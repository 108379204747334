import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  computed,
  input,
  numberAttribute,
} from "@angular/core";

@Component({
  standalone: true,
  selector: "app-avatars-stack",
  host: { class: "roam-avatars-stack" },
  styleUrl: "avatars-stack.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `
    <div class="portrait-list">
      @for (url of displayedAvatars(); track url) {
        <img class="portrait-list-item" [src]="url" />
      }
    </div>
    @if (counter(); as counter) {
      <div class="counter">+{{ counter }}</div>
    }
  `,
})
export class AvatarsStackComponent {
  readonly avatars = input.required<(string | undefined | null)[]>();
  readonly maxItems = input(3, { transform: numberAttribute });
  readonly displayedAvatars = computed(() => {
    const urls: string[] = [];
    const items = this.avatars();
    const maxLen = this.maxItems();
    for (let x = 0; x < items.length; x++) {
      const url = items[x];
      if (x < maxLen && url) {
        urls.push(url);
      }
    }
    return urls;
  });
  readonly counter = computed(() => {
    const itemsLen = this.avatars().length;
    const maxLen = this.maxItems();
    const count = itemsLen - maxLen;
    return count < 1 ? 0 : count;
  });
}
